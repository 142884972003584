import React from 'react';

import PropTypes from 'prop-types';
import Quicklink from '../QuickLink';
import styles from './CrossLinks.module.scss';
import classNames from 'classnames';

const CrossLinks = ({
    links,
    title,
    experiments,
    streamFieldPosition,
    streamFieldTotals,
}) => {
    const { quickLinksVariant } = experiments;
    const onTop = streamFieldPosition < streamFieldTotals / 2;
    const moveUp = ['MoveUp', 'MoveUpWithImages'].includes(quickLinksVariant);
    if (onTop && !moveUp) {
        return null;
    }
    const experimentClasses = {
        [styles[`CrossLinks--MobileOnly`]]: onTop && moveUp,
    };
    return (
        <div className={classNames(styles['CrossLinks'], experimentClasses)}>
            <div className={styles['CrossLinks__Container']}>
                {title && (
                    <h2 className={styles['CrossLinks__Title']}>{title}</h2>
                )}
                {links && (
                    <div className={styles['CrossLinks__Links']}>
                        {links.map((link, i) => {
                            return (
                                <Quicklink
                                    key={i}
                                    href={link.href}
                                    color="pink"
                                    title={link.title}
                                    icon="arrow"
                                    image={quickLinksVariant === 'MoveUpWithImages' ? link.image : null}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

CrossLinks.propTypes = {
    links: PropTypes.array,
    title: PropTypes.string,
    image: PropTypes.object,
    experiments: PropTypes.object,
};

CrossLinks.defaultProps = {
    links: null,
    title: null,
    image: null,
    experiments: {}
};

export default CrossLinks;
